.ChatApp {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Altura fixa para ocupar toda a viewport */
  background-color: #f5f5f5;
  overflow: hidden; /* Impede rolagem fora do container de mensagens */
}

.Chat {
  width: 90%;
  max-width: 600px;
  height: 80vh; /* Ajuste conforme necessário */
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-top: 10px; /* mudei */
} 

.ChatTitle {
  background-color: #142228;
  color: white;
  text-align: center;
  padding: 15px 0;
  margin: 0;
  font-size: 1.5em;
}

.MessageContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column; /* Agora em coluna normal */
  overflow-y: auto;
  padding: 20px;
  background-color: #e9ecef;
}

.Message {
  max-width: 80%;
  margin-bottom: 10px;
  padding: 10px 15px; /* Ajuste no padding para melhor visualização */
  background-color: #f8f9fa;
  border-radius: 18px; /* Bordas arredondadas para o efeito de balão */
  border: 1px solid #dee2e6;
  white-space: pre-wrap;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.2); /* Sombra suave para um efeito 3D */
  position: relative; /* Para posicionar elementos pseudo como setas */
}

.TypingIndicator {
  color: #666;
  padding: 10px;
  font-style: italic;
}

.UserMessage {
  background-color: #142228;
  color: white;
  border-color: #142228;
  align-self: flex-end;
  border-bottom-right-radius: 4px; /* Menos arredondado no canto inferior direito */
}

.AIMessage {
  background-color: #e9ecef;
  color: black;
  align-self: flex-start;
  border-bottom-left-radius: 4px; /* Menos arredondado no canto inferior esquerdo */
}

/* Adicionando setas aos balões de mensagem */
.UserMessage::after {
  content: '';
  position: absolute;
  right: -10px;
  bottom: 0;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent transparent #e9ecef;
}

.AIMessage::after {
  content: '';
  position: absolute;
  left: -10px;
  bottom: 0;
  border-width: 10px;
  border-style: solid;
  border-color: transparent #e9ecef transparent transparent;
}

.QuestionInput {
  display: flex;
  padding: 10px;
  background-color: #f8f9fa;
  border-top: 1px solid #dee2e6;
}

.QuestionInput input {
  flex-grow: 1;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
}

.QuestionInput button {
  padding: 10px 20px;
  background-color: #142228;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.QuestionInput button:hover {
  background-color: #2E4B58;
}
